import React, { useState } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { FaClone } from "react-icons/fa";
import { HiOutlineEye, HiOutlineTrash } from "react-icons/hi";
import { MdEmail, MdOutlineNotStarted } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {
  USER_ROLE,
  PARENT_FORM_CATEGORY_SLUG,
} from "../../../Constants/constant";
import { roleAccess } from "../../../helpers/RolesPermission";
import { parseBooleanVal } from "../../../Utils/utils";
import Loader from "../../common/Loader/Loader";
import DeleteFormModal from "./DeleteFormModal";
import { toast } from "react-toastify";
import DeleteWorkerFormModal from "./DeleteWorkerFormModal";

const FormsActionButtons = (props) => {
  const {
    role,
    item,
    parentSlug,
    participantsUid,
    participantId,
    selectedPdfId,
    handleDownload,
    isUserGracePeriodOver,
    refreshFormList,
  } = props;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedIncompleteForm, setselectedIncompleteForm] = useState({});

  const navigate = useNavigate();
  // console.log("participantsUid", participantsUid);
  const handleCloneInitialAssForm = (uuid) => {
    navigate(
      `/view-template/${participantsUid}/${parentSlug}/initial-assessment-form`,
      {
        state: {
          isCloneFormUUID: uuid,
        },
      }
    );
  };

  return (
    <>
      <Link
        to={`/${
          role === "sil"
            ? `sil-accommodation`
            : role === "hrm"
            ? "worker-profile"
            : roleAccess([USER_ROLE.Sp_Standard_User])
            ? "participant-profile"
            : "sp-profile"
        }/${parentSlug}/${item.uuid}/view`}
        state={{ particpantUuid: participantsUid }}
        className='text-indigo-600 hover:text-indigo-900 cursor-pointer'
      >
        <div className='icon-eye-wrapper'>
          <HiOutlineEye className='icon-size' />
        </div>
      </Link>

      {roleAccess([USER_ROLE.Sp_Standard_User]) &&
        item.is_complete &&
        (item.category_name === PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.NAME ||
          item.category_name === PARENT_FORM_CATEGORY_SLUG.MEDICATION.NAME ||
          item.category_name ===
            PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.NAME) && (
          <Link
            role='button'
            to={`${
              role === "sil"
                ? "/sil-accommodation"
                : role === "hrm"
                ? "/worker-profile"
                : "/participant-profile"
            }/${parentSlug}/${item.uuid}/update`}
            state={{ particpantUuid: participantsUid }}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='icon-pencil-wrapper'>
              <BiPencil className='icon-size' />
            </div>
          </Link>
        )}

      {!roleAccess([USER_ROLE.Sp_Standard_User]) &&
        parseBooleanVal(item.is_complete) && (
          <Link
            role='button'
            to={`${
              role === "sil"
                ? "/sil-accommodation"
                : role === "hrm"
                ? "/worker-profile"
                : "/sp-profile"
            }/${parentSlug}/${item.uuid}/update`}
            state={{
              participantId: participantId,
              particpantUuid: participantsUid,
            }}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='icon-pencil-wrapper'>
              <BiPencil className='icon-size' />
            </div>
          </Link>
        )}

      {!roleAccess([USER_ROLE.Sp_Standard_User]) &&
        !parseBooleanVal(item.is_complete) && (
          <Link
            to={`/view-template/${
              role === "sil" ? "sil/" : role === "hrm" ? "hrm/" : ""
            }${participantsUid}/${parentSlug}/${item.uuid}/${window.btoa(
              encodeURIComponent("false")
            )}`}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='icon-stopPlay-wrapper'>
              <MdOutlineNotStarted className='icon-size' />
            </div>
          </Link>
        )}

      <button
        onClick={() => {
          if (!parseBooleanVal(item.is_complete)) {
            setselectedIncompleteForm(item);
            setOpenDeleteModal(true);
          } else {
            toast.error("Only incomplete forms can be deleted.");
          }
        }}
      >
        <div className='icon-delete-wrapper' title='Delete'>
          <HiOutlineTrash className='icon-size' />
        </div>
      </button>

      {openDeleteModal && (
        <>
          {parentSlug === "worker-profile" || role === "hrm" ? (
            <DeleteWorkerFormModal
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
              isUserGracePeriodOver={isUserGracePeriodOver}
              handleClose={() => setOpenDeleteModal(false)}
              uuid={selectedIncompleteForm.uuid}
              refreshFormList={refreshFormList}
              role={role}
            />
          ) : (
            <DeleteFormModal
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
              isUserGracePeriodOver={isUserGracePeriodOver}
              handleClose={() => setOpenDeleteModal(false)}
              uuid={selectedIncompleteForm.uuid}
              refreshFormList={refreshFormList}
              role={role}
            />
          )}
        </>
      )}
      {!roleAccess([USER_ROLE.Sp_Standard_User]) &&
        !parseBooleanVal(item.is_complete) && (
          <Link
            to={`/view-template/${
              role === "hrm" ? "hrm/" : ""
            }${participantsUid}/${parentSlug}/${item.uuid}/${window.btoa(
              encodeURIComponent("false")
            )}`}
            className='text-indigo-600 hover:text-indigo-900'
          >
            <div className='cursor-pointer hover:text-white hover:border-purple-700 hover:bg-purple-700 hover:transition-all-custom text-purple-700   border border-purple-700  p-1 rounded-md '>
              <MdEmail className='h-3.5 w-3.5' />
            </div>
          </Link>
        )}

      {item.title &&
        item.title.toLowerCase() === "ndis service agreement form" &&
        parseBooleanVal(item.is_complete) && (
          <>
            {selectedPdfId === item.uuid ? (
              <div>
                <Loader cssClass='text-black mb-1' />
              </div>
            ) : (
              <div
                onClick={() => handleDownload(item.uuid)}
                className='icon-filePdf-wrapper cursor-pointer'
              >
                <AiOutlineFilePdf className='icon-size' />
              </div>
            )}
          </>
        )}

      {item.title &&
        item.title.toLowerCase() === "initial assessment form" &&
        parseBooleanVal(item.is_complete) &&
        roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) && (
          <div
            onClick={() => handleCloneInitialAssForm(item.uuid)}
            className='icon-clone-wrapper cursor-pointer'
          >
            <FaClone className='icon-size' />
          </div>
        )}
    </>
  );
};

export default FormsActionButtons;
