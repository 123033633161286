import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { baseURLImage, getNameProfile } from "Utils/utils";

const OccupantListOptions = [
  { value: "participant-list", name: "Assigned Participants" },
  { value: "worker-list", name: "Assigned Workers" },
  { value: "manager-list", name: "Assigned Regional Managers" },
];
const OccupantList = ({ selectedAccommodationInfo }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(OccupantListOptions[0].value);
  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  const userTabList = useMemo(() => {
    if (selectedTab === "participant-list") {
      return selectedAccommodationInfo?.participantsList;
    } else if (selectedTab === "worker-list") {
      return selectedAccommodationInfo?.workersList?.map((x) => ({
        ...x,
        profile_picture: x.photo,
      }));
    }
    return selectedAccommodationInfo?.managersList?.map((x) => ({
      ...x,
      profile_picture: null,
    }));
  }, [selectedTab, selectedAccommodationInfo]);

  return (
    <>
      <div
        className='bg-[#F6F7F8]'
        style={{
          borderTopRightRadius: "15px",
          borderTopLeftRadius: "15px",
        }}
      >
        <div className=' py-2'>
          <div className='flex border-b-2 w-full'>
            {OccupantListOptions.map((tab) => (
              <span
                key={tab.id}
                className={`text-center  sm:text-xs py-2 px-1 w-full cursor-pointer ${
                  tab.value === selectedTab
                    ? "border-b-2 border-blue-500 font-bold text-[#0177FB] "
                    : "text-gray-500 font-bold hover:text-gray-500"
                }`}
                onClick={() => setSelectedTab(tab.value)}
              >
                {tab.name}
              </span>
            ))}
          </div>
          {OccupantListOptions.map((tab) => (
            <div
              key={tab.id}
              className={`${
                tab.value === selectedTab ? "block" : "hidden"
              } px-6 mt-2 flex justify-start h-[340px] !h-max-[340px] overflow-y-auto custom-scroll`}
            >
              {userTabList?.length > 0 ? (
                <ul className='divide-y divide-gray-200'>
                  {userTabList?.map((item, index) => (
                    <li key={index} className='py-1.5'>
                      <div
                        role='button'
                        onClick={() => {
                          if (selectedTab === "participant-list") {
                            navigate(`/sp-profile/${item.uuid}`);
                          } else if (selectedTab === "worker-list") {
                            navigate(`/worker-profile/${item.uuid}`);
                          }
                        }}
                        className='flex space-x-3'
                      >
                        <img
                          onError={(e) =>
                            handleImageError(
                              e,
                              item?.name ? item.name : item?.first_name,
                              item?.name ? "" : item?.last_name
                            )
                          }
                          className='h-10 w-10 rounded-full object-cover'
                          src={
                            item?.profile_picture !== null
                              ? baseURLImage(item?.profile_picture)
                              : getNameProfile(
                                  item?.name ? item.name : item?.first_name,
                                  item?.name ? " " : item?.last_name
                                )
                          }
                          alt=''
                        />
                        <div className='flex-1'>
                          <div className='flex items-center justify-between'>
                            <h3 className='text-sm font-medium capitalize'>{`${
                              item?.name ? item.name : item?.first_name
                            } ${item?.name ? " " : item?.last_name}`}</h3>
                          </div>
                          <p className='text-sm text-gray-500'>{item?.email}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className='w-full text-sm text-red-500 font-semibold text-gray-900 py-3 flex justify-center items-center space-x-3'>
                  <span className='text-red-500'>
                    No{" "}
                    {`${
                      selectedTab === "participant-list"
                        ? "Participant"
                        : selectedTab === "worker-list"
                        ? "Worker"
                        : "Data"
                    }`}{" "}
                    Found!
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OccupantList;
