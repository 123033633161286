import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import CustomButton from "components/common/Button/CustomButton";
import SilHomeImg from "assets/images/sil-home.png";
import SilHomeQuickSnapshots from "components/common/QuickSnapshots/SilHomeQuickSnapshots";
import QuickViewTabs from "./QuickViewTabs";
import api from "store/services/sil.services";
import { baseURLImage } from "Utils/utils";

const SilAccommodationQuickView = (props) => {
  const { selectedHome, isOpen, setIsOpen } = props;

  const QUICKVIEW_TABS = [
    { value: "participant-list", name: "Participant List" },
    { value: "worker-list", name: "Worker List" },
    { value: "utility-provider-list", name: "Utility Provider List" },
  ];

  const [selectedTab, setSelectedTab] = useState(QUICKVIEW_TABS[0].value);
  const navigate = useNavigate();

  const [propertyData, setPropertyData] = useState(null);
  const [isFetchUtilityProviderLoader, setIsFetchUtilityProviderLoader] =
    useState(false);
  const [utilityProviderData, setUtilityProviderData] = useState(null);

  const [request, setRequest] = React.useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  useEffect(() => {
    if (selectedHome?.uuid) {
      getHomeQuickViewData();
      getUtilityProviderData();
    }
  }, []);

  const getHomeQuickViewData = async () => {
    try {
      setRequest((prev) => ({
        ...prev,
        isRequested: true,
        isFailed: "",
      }));
      const response = await api.silAccommodationQuickViewApi(
        selectedHome.uuid
      );
      setRequest((prev) => ({
        ...prev,
        isRequested: false,
        isSuccess: true,
      }));
      setPropertyData(response);
    } catch (err) {
      setRequest((prev) => ({
        ...prev,
        isRequested: false,
        isFailed: err?.message,
      }));
      console.log("err:getHomeQuickViewData", err);
    }
  };

  // console.log("selectedHome", selectedHome);
  // console.log("selectedTab", selectedTab);
  const userTabList = useMemo(() => {
    if (selectedTab === "participant-list") {
      return propertyData?.participants;
    } else if (selectedTab === "worker-list") {
      return propertyData?.workers?.map((x) => ({
        ...x,
        profile_picture: x.photo,
      }));
    }
    // return propertyData?.managers?.map((x) => ({
    //   ...x,
    //   profile_picture: null,
    // }));
    return utilityProviderData;
  }, [selectedTab, propertyData]);

  const transformData = (data = []) => {
    return data
      .map((section) => {
        return section.input_fields
          .map((field) => {
            if (
              field.name === "electricity_company_name" ||
              field.name === "gas_company_name" ||
              field.name === "water_company_name" ||
              field.name === "internet_company_name" ||
              field.name === "telephone_company_name"
            ) {
              const name = field.lable;
              const companyName = field.value;
              const email =
                section.input_fields.find((f) => f.name === "company_email")
                  ?.value || null;
              const phone =
                section.input_fields.find(
                  (f) => f.name === "company_phone_number"
                )?.value || null;

              if (email || phone || companyName) {
                return {
                  name: name,
                  email: email,
                  phone: phone,
                  companyName,
                };
              }
            }
            return null;
          })
          .filter((item) => item !== null);
      })
      .flat();
  };

  const getUtilityProviderData = async () => {
    try {
      setIsFetchUtilityProviderLoader(true);
      const response = await api.silHomeNearByPropertyDataApi({
        home_id: selectedHome.uuid,
        slug: "utility-provider-listing",
      });
      if (response?.submitted_form) {
        const data = JSON.parse(response.submitted_form?.template);
        setUtilityProviderData(transformData(data));
      }
      setIsFetchUtilityProviderLoader(false);
    } catch (err) {
      setIsFetchUtilityProviderLoader(false);

      console.log("err", err);
    }
  };
  // console.log("propertyData", propertyData);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-50' onClose={setIsOpen}>
        <div className='min-h-screen absolute top-0 right-0'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-blue-dark/30' />
          </Transition.Child>

          <span className='inline-block h-screen' aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-500'
            enterFrom='transform translate-x-full opacity-0'
            enterTo='transform translate-x-0 opacity-100'
            leave='ease-in duration-500'
            leaveFrom='transform translate-x-0 opacity-100'
            leaveTo='transform translate-x-full opacity-0'
          >
            <div
              style={{ float: "right", maxWidth: "500px", width: "500px" }}
              className='min-h-screen inline-block py-2 text-left align-middle transition-all transform bg-white shadow-xl border border-gray-100 min-h-screen max-h-screen overflow-auto custom-scroll'
            >
              {request.isRequested ? (
                <div
                  className='min-h-screen flex justify-center items-center'
                  style={{ minWidth: "25rem", overflow: "hidden !important" }}
                >
                  <CircularLoader
                    classes='flex justify-center items-center'
                    classloader='loader-l'
                  />
                </div>
              ) : (
                <>
                  <div className='px-6'>
                    <div className='flex justify-between items-center'>
                      <span className='font-bold capitalize'>Quick View</span>
                      <button onClick={setIsOpen}>
                        <AiOutlineClose className='h-5 w-5 hover:text-gray-400' />
                      </button>
                    </div>
                    {propertyData?.uuid && (
                      <div className='py-5 flex items-start space-x-4'>
                        <div className=''>
                          <span className='text-lg font-bold'>
                            {`${propertyData?.street_address}, ${
                              propertyData?.suburb
                            }, ${propertyData?.state?.name?.toUpperCase()} ${
                              propertyData?.postal_code || ""
                            }`}
                          </span>
                          {/* <p className='text-base text-gray-600'>
                        Richmond / Postal code 56000 /melbourne australia
                      </p> */}
                        </div>
                      </div>
                    )}
                    <div className='flex justify-center items-center'>
                      <img
                        className='max-h-[165px]'
                        src={
                          propertyData?.images?.length > 0
                            ? baseURLImage(propertyData?.images[0]?.path)
                            : SilHomeImg
                        }
                        alt='home'
                      />
                    </div>

                    <div className='mt-4 flex space-x-4'>
                      <CustomButton
                        variant='primary'
                        clickHandler={() => {
                          console.log("property proflie");
                          navigate(`/sil-accommodation/${selectedHome?.uuid}`);
                        }}
                        label='Home Profile'
                      />
                    </div>

                    <div className='mt-1'>
                      <SilHomeQuickSnapshots
                        isQuickViewCall={true}
                        cssClass='mt-0'
                        paddingLeft={true}
                        propertyData={propertyData}
                      />
                    </div>
                  </div>
                  <div className=''>
                    <QuickViewTabs
                      tabs={QUICKVIEW_TABS}
                      handleChange={(e) => setSelectedTab(e)}
                      selectedTab={selectedTab}
                      data={userTabList}
                      type={selectedTab}
                    />
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SilAccommodationQuickView;
